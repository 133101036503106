import { useState } from "react";
import { Col, Offcanvas, Row, Tab, Tabs } from "react-bootstrap";
import { AiOutlineHeart } from "react-icons/ai";
import { MdClose, MdOutlineShoppingBag } from "react-icons/md";
import { RiMenu5Line } from "react-icons/ri";
import { Blob_Base_Url } from "../../utils/static";

const MobileMenu = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        role="button"
        onClick={() => setShow(true)}
        className="me-2 fs-4 d-md-none"
      >
        <RiMenu5Line />
      </div>
      <Offcanvas show={show}>
        <Row className="px-4 py-3">
          <Col
            xs="2 fs-3 fw-bolder"
            role="button"
            onClick={() => setShow(false)}
          >
            <MdClose />
          </Col>
          <Col>
            <div className="logo">
              <img src={Blob_Base_Url + "/static/images/logo.png"} />
            </div>
          </Col>
        </Row>
        <Line />
        <Row className="py-2 px-4 mb-3">
          <Box xs="12 mb-3">
            <Row className="align-items-center">
              <Col xs="auto">
                <div className="bg-danger rounded-circle overflow-hidden">
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={Blob_Base_Url + "/static/images/user.png"}
                    alt="User"
                  />
                </div>
              </Col>
              <Col>
                <span className="font-e-lg">Hello, Please Signin</span>
              </Col>
            </Row>
          </Box>
          <Box>
            <AiOutlineHeart className="px-1 py-1 fs-2 bg-light rounded-circle me-2" />
            <span className="font-lg">Wishlist</span>
          </Box>
          <Box>
            <MdOutlineShoppingBag className="px-1 py-1 fs-2 bg-light rounded-circle me-2" />
            <span className="font-lg">Orders</span>
          </Box>
        </Row>
        <Line />
        <Tabs>
          <Tab title="Menus">Lof</Tab>
        </Tabs>
      </Offcanvas>
    </>
  );
};

export default MobileMenu;

const Box = ({ children, ...props }) => (
  <Col {...props}>
    <div className="border rounded-7 py-2 px-4">{children}</div>
  </Col>
);

const Line = () => <p className="py-1 w-100 bg-light rounded-pill" />;
